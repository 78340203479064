import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import ContainTable, { RelationFine, Budget } from '../components';

formCreate.component('ContainOrg', ContainTable);
formCreate.component('RelationFine', RelationFine);
formCreate.component('Budget', Budget);
export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'tpm_cost_type_categories_form',
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'categoriesCode') {
        v.validate = [
          ...v.validate,
          {
            trigger: 'change',
            pattern: /^[a-z|A-Z|0-9]*$/,
            message: '活动大类编码只能是字母和数字',
          },
        ];
      } else if (v.field === 'rangeList') {
        v.value = {
          containOrgRangeVos: [],
          containOrgTypeRangeVos: [],
        };
      } else if (v.field === 'relationFine') {
        v.value = [];
      } else if (v.field === 'budgetSubjectsVoList') {
        v.value = [];
      }

      // else if (v.field === 'formCode') {
      //   v.restful = '/mdm/mdmfunctionsub/sub_list';
      //   v.restfulParams = {
      //     functionCode: 'act-category-template',
      //     parentCode: 'CRM20201126000000093',
      //   };
      //   v.optionsKey = { label: 'functionName', value: 'functionCode' };
      //   v.props = {
      //     ...v.porps,
      //     filterable: true,
      //     remote: true,
      //     remoteParams: 'functionName',
      //   };
      // }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmCostTypeCategoriesController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              // 范围信息
              data.rangeList = {
                containOrgRangeVos: data.containOrgRangeVos ? data.containOrgRangeVos.map((item) => {
                  const v = item;
                  v.orgCode = v.rangeCode;
                  v.orgName = v.rangeName;
                  v.editId = v.id;
                  return v;
                }) : [],
                containOrgTypeRangeVos: data.containOrgTypeRangeVos ? data.containOrgTypeRangeVos.map((item) => {
                  const v = item;
                  v.dictCode = v.rangeCode;
                  v.dictValue = v.rangeName;
                  v.editId = v.id;
                  return v;
                }) : [],
              };

              data.activityCategoriesType = JSON.parse(data.activityCategoriesType);
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const { rangeList, ...param } = formData;
        const url = '/tpm/tpmCostTypeCategoriesExt/save';
        const params = { ...param, ...rangeList };
        if (this.formConfig.code === 'edit') {
          params.id = this.formConfig.row.id;
        }
        // 该字段必传，但是已经没有了，所以随便写
        params.budgetSubjectsCode = '测试固定值';
        params.budgetSubjectsName = '测试固定值';
        // 范围信息
        params.containOrgRangeVos = params.containOrgRangeVos.map((item) => {
          const v = item;
          if (v.editId) {
            v.id = v.editId;
            delete v.editId;
          }
          return v;
        });
        params.containOrgTypeRangeVos = params.containOrgTypeRangeVos.map((item) => {
          const v = item;
          if (v.editId) {
            v.id = v.editId;
            delete v.editId;
          }
          return v;
        });

        params.activityCategoriesType = JSON.stringify(params.activityCategoriesType);

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
