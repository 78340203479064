<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template #buttons>
        <el-link icon="el-icon-plus" @click="addLine">新增行</el-link>
        <el-link icon="el-icon-plus" @click="addTable">批量新增</el-link>
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable">清空</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table ref="fineTable" :data="value" :edit-config="{trigger: 'click', mode: 'cell'}">
      <vxe-table-column
        field="fineCode"
        title="活动细类编号"
        :edit-render="!this.disabled ? {
          name: '$select',
          options: fineDataList,
          props: { 'popper-class': 'fine-select', placeholder: '请选择活动细类', transfer: true },
          events: {
            change: this.changeRow
          }
        }: null"
      ></vxe-table-column>
      <vxe-table-column field="fineName" title="活动细类名称"></vxe-table-column>
      <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
        <template #default="{ rowIndex }">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeEvent(rowIndex)"
          >
            <el-button slot="reference" type="text" class="text-red">
              <i class="el-icon-delete"></i>
            </el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';
import request from '../../../../../../utils/request';

export default {
  name: 'ContainOrg',
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      fineDataList: [], // 全部细类数据
    };
  },
  components: {
    SelectConfig,
  },
  created() {},
  mounted() {
    this.getFineList();
  },
  methods: {
    // 获取下拉列表
    getFineList() {
      request
        .post(
          '/tpm/tpmCostTypeFineController/list',
          { pageSize: -1 },
          { headers: { functionCode: 'select-fine-class-activities' } },
        )
        .then((res) => {
          if (res.success) {
            const list = res.result.data;

            this.fineDataList = list.map((v) => ({
              ...v,
              label: `${v.fineCode}｜${v.fineName}`,
              value: v.fineCode,
            }));
          }
        });
    },
    // 新增行
    addLine() {
      const list = JSON.parse(JSON.stringify(this.value || []));
      list.unshift({
        fineCode: '',
        fineName: '',
      });

      this.$emit('input', list);
    },
    // 批量新增
    addTable() {
      const params = {
        functionCode: 'tpm_fine_list',
        data: this.value || [],
        idKey: 'fineCode',
      };

      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(val) {
      const list = val.map((v) => ({
        ...v,
        label: `${v.fineCode}｜${v.fineName}`,
        value: v.fineCode,
      }));
      this.$emit('input', list);
    },
    // 编辑监听
    changeRow({ rowIndex }) {
      const list = JSON.parse(JSON.stringify(this.value || []));
      const item = this.fineDataList.find(
        (v) => v.value === list[rowIndex].fineCode,
      );

      list[rowIndex].fineName = item.fineName;
      this.$emit('input', list);
    },
    // 清空
    clearTable() {
      this.$emit('input', []);
    },
    // 删除
    removeEvent(index) {
      const list = this.value.filter((v, k) => k !== index);
      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 30px;
  box-sizing: border-box;

  .text-red {
    color: #f56c6c !important;
  }
}
</style>
