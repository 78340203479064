<template>
  <div class="edit-table">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="组织" name="first"></el-tab-pane>
      <el-tab-pane label="组织类型" name="second"></el-tab-pane>
    </el-tabs>
    <vxe-toolbar v-if="!disabled">
      <template #buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addFn">{{
          activeName === "first" ? "添加组织" : "添加组织层级"
        }}</el-link>
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable">清空</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table :data="tableList">
      <vxe-table-column v-for="(config, index) in columnList" :key="index" v-bind="config">
      </vxe-table-column>
      <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
        <template #default="{ row, rowIndex }">
          <div class="setting-btn">
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row, rowIndex)"
            >
              <el-button slot="reference" type="text" class="text-red">
                <i class="el-icon-delete"></i>
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

const orgColumn = [
  { field: 'rangeCode', title: '组织编码' },
  { field: 'rangeName', title: '组织名称' },
];
const orgTypeColumn = [
  { field: 'rangeCode', title: '组织类型编码' },
  { field: 'rangeName', title: '组织类型名称' },
];

export default {
  name: 'ContainOrg',
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      activeName: 'first',
      tableList: [],
      columnList: orgColumn,
    };
  },
  components: {
    SelectConfig,
  },
  watch: {
    value(val) {
      this.tableList = val.containOrgRangeVos;
    },
  },
  computed: {},
  created() {},
  methods: {
    // 标签页切换
    handleClick(tab) {
      this.activeName = tab.name;
      if (tab.name === 'first') {
        this.tableList = this.value.containOrgRangeVos;
        this.columnList = orgColumn;
      } else if (tab.name === 'second') {
        this.tableList = this.value.containOrgTypeRangeVos;
        this.columnList = orgTypeColumn;
      }
    },
    // 弹框选择列表
    addFn() {
      let params = {};

      if (this.activeName === 'first') {
        params = {
          functionCode: 'tpm_cost_type_org',
          data: this.tableList || [],
          idKey: 'orgCode',
        };
      } else {
        params = {
          functionCode: 'tpm_org_type_list',
          data: this.tableList || [],
          idKey: 'dictCode',
          paramData: {
            dictTypeCode: 'mdm_org_type',
          },
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择数据回调
    onGetSelect(val) {
      const type = this.activeName;
      const list = val.map((item) => {
        const v = item;
        let subItem = {};

        if (type === 'first') {
          subItem = {
            rangeType: 1,
            orgCode: v.orgCode,
            orgName: v.orgName,
            rangeCode: v.orgCode,
            rangeName: v.orgName,
          };
        } else {
          subItem = {
            rangeType: 2,
            dictCode: v.dictCode,
            dictValue: v.dictValue,
            rangeCode: v.dictCode,
            rangeName: v.dictValue,
          };
        }
        return {
          editId: v.editId,
          isContain: 'Y',
          isOnlyCurrent: 'Y',
          ...subItem,
        };
      });

      if (type === 'first') {
        this.value.containOrgRangeVos = list;
      } else {
        this.value.containOrgTypeRangeVos = list;
      }
      this.tableList = list;
    },
    // 删除行数据
    removeRow(row, rowIndex) {
      const list = this.tableList.filter((v, k) => k !== rowIndex);

      if (this.activeName === 'first') {
        this.value.containOrgRangeVos = list;
      } else {
        this.value.containOrgTypeRangeVos = list;
      }
      this.tableList = list;
      this.$emit('input', this.value);
    },
    // 清空表格数据
    clearTable() {
      if (this.activeName === 'first') {
        this.value.containOrgRangeVos = [];
      } else {
        this.value.containOrgTypeRangeVos = [];
      }
      this.tableList = [];
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 30px;
  box-sizing: border-box;

  .text-red {
    color: #f56c6c!important;
  }
}
</style>
